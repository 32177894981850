// Build information, automatically generated by `ng-info`
const build = {
    version: "0.0.0",
    timestamp: "Mon Jul 01 2024 11:49:57 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "staging",
        hash: "d6723e",
        fullHash: "d6723e60c4f4326f7a34a86dd0d5259b19b1dbb2"
    }
};

export default build;