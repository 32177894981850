<h1>
    @switch (calendarMode) {
        @case ("month") {
            {{ selectedDate | date: "MMM dd, yyyy (EEE)" }}
        }

        @case ("year") {
            {{ selectedDate | date: "MMM yyyy" }}
        }
    }
</h1>

<nz-spin [nzSpinning]="showLoading">
    @if (data.shortTerm.length > 0) {
        <nz-list nzItemLayout="vertical">
            @for (cardData of data.shortTerm; track cardData) {
                <nz-list-item>
                    <insiden-event-card [data]="cardData" />
                </nz-list-item>
            }
        </nz-list>
    }

    @if (data.longTerm.length > 0) {
        <div>
            <h2>Long term incidents</h2>
            <nz-collapse>
                <nz-collapse-panel
                    [nzHeader]="
                        panelActive
                            ? 'Click to Collapse '
                            : 'Click to Expand (' +
                              data.longTerm.length.toString() +
                              (data.longTerm.length > 1
                                  ? ' incidents'
                                  : ' incident') +
                              ')'
                    "
                    [(nzActive)]="panelActive"
                >
                    <nz-list nzItemLayout="vertical">
                        @for (cardData of data.longTerm; track cardData) {
                            <nz-list-item>
                                <insiden-event-card [data]="cardData" />
                            </nz-list-item>
                        }
                    </nz-list>
                </nz-collapse-panel>
            </nz-collapse>
        </div>
    } @else if (dataLength === 0 && !showLoading) {
        <nz-empty
            nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        />
    }
</nz-spin>
