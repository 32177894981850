<d-common-header
    [showShadow]="false"
    [hasMaxWidth]="false"
    [isFixed]="true"
    [showShadow]="true"
    [userAvatar]="userAvatar"
    class="header"
>
    <d-header-logo [name]="header"></d-header-logo>
    <d-header-menu
        [menuList]="innerMenuList"
        [selectedItem]="innerMenuList[0]"
    ></d-header-menu>
</d-common-header>
<div [class]="applyPadding ? 'content-outer-container' : ''">
    <div
        [class]="
            applyPadding
                ? 'content-inner-container'
                : applyTopPadding
                  ? 'top-margin-only'
                  : ''
        "
    >
        <!-- <nz-alert
            nzBanner
            nzType="info"
            nzMessage="Upgrades incoming! 🥳 We will be undergoing maintenance on 0000hrs, 10 May 2023 (GMT+08) to facilitate for upgrades to better infrastructure. The operation will take an estimated time of 30 minutes. 😎"
        ></nz-alert> -->
        <router-outlet
            [class]="applyPadding ? 'main-container' : ''"
        ></router-outlet>
    </div>
</div>
@if (applyPadding) {
    <div class="footer">
        <hr />
        <app-footer></app-footer>
    </div>
}
