<div class="header-menu">
    @for (item of menuList; track item) {
        <div
            class="menu-item"
            (click)="onSelect(item)"
            [ngClass]="{
                'menu-selected-item':
                    item.href.split('/')[1] === router.url.split('/')[1]
            }"
        >
            @if (item.href) {
                <a
                    class="menu-link"
                    [href]="item.href"
                    rel="noopener noreferrer"
                    [routerLink]="item.href"
                >
                    @if (item.tag) {
                        <d-badge
                            [count]="item.tag"
                            [offset]="[5, -8]"
                            [status]="item.style"
                        >
                            {{ item.name }}
                        </d-badge>
                    } @else {
                        {{ item.name }}
                    }
                </a>
            }
        </div>
    }

    <div class="menu-item">
        <a
            class="menu-link"
            [href]="'https://github.com/kwongtn/rosak_firebase'"
            rel="noopener noreferrer"
            target="_blank"
        >
            <span nz-icon nzType="github" nzTheme="outline"></span>
        </a>
    </div>

    <div class="menu-item">
        <i
            class="icon icon-code-editor-light menu-item-theme"
            (click)="toggleTheme()"
        >
        </i>
    </div>

    <div
        dTooltip
        class="menu-item"
        [content]="'Report a bug'"
        [position]="'left'"
    >
        <i class="icon icon-bug menu-link" (click)="reportBug()"></i>
    </div>

    @if (countIcon > 0 || hadUpload) {
        <div
            dTooltip
            class="menu-item"
            [content]="
                countIcon == 0
                    ? 'All uploads complete'
                    : countIcon + ' uploads pending'
            "
            [position]="'left'"
        >
            <nz-badge
                nzStandalone
                [nzCount]="
                    countIcon == 0 && hadUpload ? iconTemplate : countIcon
                "
            ></nz-badge>
        </div>
    }
</div>

<ng-template #iconTemplate>
    <span
        nz-icon
        [nzType]="'check-circle'"
        [nzTheme]="'twotone'"
        [nzTwotoneColor]="'#52c41a'"
    ></span>
</ng-template>
