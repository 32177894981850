<nz-space nzDirection="vertical">
    <span *nzSpaceItem>
        @if (authService.userData | async) {
            <nz-card>
                <nz-card-meta
                    [nzTitle]="titleTemplate"
                    [nzAvatar]="avatarTemplate"
                    nzDescription="{{ (authService.userData | async)?.email }}"
                />
                <div class="card-content"></div>
            </nz-card>
        }

        <ng-template #titleTemplate>
            <a [href]="profileHref" [routerLink]="profileHref" class="text">{{
                (authService.userData | async)?.displayName
            }}</a>
        </ng-template>

        <ng-template #avatarTemplate>
            <nz-avatar [nzSrc]="userAvatar" [nzSize]="85"></nz-avatar>
        </ng-template>
    </span>
    <verification-code-card *nzSpaceItem />
</nz-space>

<div class="theme-menu">
    <nz-switch
        class="theme-follow-toggle"
        [(ngModel)]="themeFollowSystemColorScheme"
        (ngModelChange)="followSystemColorScheme($event)"
    />
    <span>Follow System Theme</span>
</div>

<div class="theme-menu">
    @if (authService.userData | async) {
        <button nz-button nzType="primary" (click)="authService.logout()">
            Logout
        </button>
    } @else if ((authService.userData | async) == null) {
        <button nz-button nzType="primary" (click)="authService.login()">
            Login with Google
        </button>
    }
</div>
