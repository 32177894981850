<div
    class="fixed-wapper"
    [ngClass]="{
        'header-fixed': isFixed
    }"
>
    <div
        class="header-wapper"
        [ngClass]="{
            'header-shadow': showShadow,
            'header-maxwidth': hasMaxWidth
        }"
    >
        <div class="header-left">
            @if (showSlideButton) {
                <div class="slide-menu-link" (click)="clickSlideMenu()">
                    <span>
                        <svg
                            width="18px"
                            height="14px"
                            viewBox="0 0 18 14"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g
                                id="slide-button-outer"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <g id="slide-button-inner">
                                    <path
                                        d="M18,6 L18,8 L0,8 L0,6 L18,6 Z M18,0 L18,2 L0,2 L0,0 L18,0 Z M14,12 L14,14 L0,14 L0,12 L14,12 Z"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
            }
            <ng-content select="d-header-logo"></ng-content>
        </div>
        <div class="header-right" [ngClass]="{ active: collapseMenuActive }">
            <ng-content select="d-header-menu"></ng-content>
            <ng-content select="d-ecosystem"></ng-content>

            <div (click)="onLoginIconClick()" class="header-avatar">
                @if (userAvatar) {
                    <d-avatar
                        [width]="40"
                        [height]="40"
                        [imgSrc]="userAvatar"
                    />
                } @else {
                    <d-avatar
                        dTooltip
                        [name]="''"
                        [content]="'Click here to login'"
                        [position]="'left'"
                    />
                }
            </div>
        </div>
        <div
            id="headerCollapseMenu"
            class="header-collapse-menu"
            [ngClass]="{ active: collapseMenuActive }"
            (click)="toggleMenu($event)"
        >
            <span></span>
        </div>
    </div>
    @if (environment.sentry.environment == "staging") {
        <nz-alert
            nzBanner
            nzMessage="You are in staging environment. Take note that all progress will be reset to public build everyday at 03:00 GMT+08."
        />
    }
</div>
