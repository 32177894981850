<nz-spin [nzSpinning]="showLoading">
    <nz-calendar
        [(ngModel)]="selectedDate"
        (nzSelectChange)="selectChange($event)"
        (nzPanelChange)="panelChange($event)"
    >
        <ul *nzDateCell="let date" class="events">
            @switch (date | date: "yyyy-MM-dd") {
                @case (date | date: "yyyy-MM-dd") {
                    <li>
                        @for (
                            item of monthEvents[
                                (date | date: "yyyy-MM-dd") ?? ""
                            ] | keyvalue;
                            track item
                        ) {
                            @if (!item.value.isLongTerm) {
                                <nz-badge
                                    class="count-badge"
                                    nzStandalone
                                    [nzCount]="item.value.count"
                                    [nzStyle]="{
                                        backgroundColor:
                                            (item.key
                                            | uppercase
                                            | calendarIncidentSeverity: 'color')
                                    }"
                                />
                            }
                        }
                    </li>
                    <li>
                        @for (
                            item of monthEvents[
                                (date | date: "yyyy-MM-dd") ?? ""
                            ] | keyvalue;
                            track item
                        ) {
                            @if (item.value.isLongTerm) {
                                <span
                                    class="long-term-indicator"
                                    nz-icon
                                    nzType="clock-circle"
                                    nz-tooltip
                                    nzTooltipTitle="Long-term incident"
                                    nzTooltipPlacement="topLeft"
                                    [nzTooltipArrowPointAtCenter]="true"
                                ></span>
                                <nz-badge
                                    class="count-badge"
                                    nzStandalone
                                    [nzCount]="item.value.count"
                                    [nzStyle]="{
                                        backgroundColor:
                                            (item.key
                                            | uppercase
                                            | calendarIncidentSeverity: 'color')
                                    }"
                                />
                            }
                        }
                    </li>
                }
            }
        </ul>

        <ng-container *nzMonthCell="let month" class="events">
            @switch (month | date: "yyyy-MM") {
                @case (month | date: "yyyy-MM") {
                    @for (
                        item of yearEvents[(month | date: "yyyy-MM") ?? ""]
                            | keyvalue;
                        track item
                    ) {
                        <nz-badge
                            class="count-badge"
                            nzStandalone
                            [nzCount]="item.value"
                            [nzStyle]="{
                                backgroundColor:
                                    (item.key
                                    | uppercase
                                    | calendarIncidentSeverity: 'color')
                            }"
                        />
                    }
                }
            }
        </ng-container>
    </nz-calendar>
</nz-spin>
