<footer>
    <div class="copyright">
        Frontend build <i>{{ buildInfo.git.hash }}</i> &#64;
        {{ buildInfo.timestamp }}<br />
        Backend build <i>{{ backendBuildInfo.hash }}</i> &#64;
        {{ backendBuildInfo.datetime }}<br />
        GNU AGPLv3 by
        <a href="http://github.com/kwongtn" target="_blank"> KwongTN </a>
        & contributors
    </div>
</footer>
